// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css';
import 'select2/dist/css/select2.css';
import 'select2';

/* global document */
document.addEventListener('DOMContentLoaded', () => {
  $('.select2').select2({
    theme: 'bootstrap-5',
    width: '100%',
    placeholder: () => {
      $(this).data('placeholder');
    },
  });

  $('body').on('change', '#vehicle_manufacturer', function () {
    const $type = $(this).parents('form').find('#vehicle_type');
    const $model = $(this).parents('form').find('#vehicle_model');
    const linkingTargetType = $(this).data('linking-target-type') || 'P';
    const ajaxUrl = $(this).data('url') || '/ajax/models';
    const url = ajaxUrl.replace(/0/, $(this).val());

    $type.prop('disabled', true).val('');
    $model.prop('disabled', true).val('');

    $.get(url + '/' + linkingTargetType, (data) => {
        $model.find('option').not(':first').remove();
        $model.prop('disabled', false);

        $.each(data, (_, model) => {
            const option = new Option(model.name, model.value);

            $model.append($(option));
        });
    });
});

$('body').on('change', '#vehicle_model', function () {
    const $type = $(this).parents('form').find('#vehicle_type');
    const $manu = $(this).parents('form').find('#vehicle_manufacturer');
    const linkingTargetType = $(this).data('linking-target-type') || 'P';
    const ajaxUrl = $(this).data('url') || '/ajax/types';
    $type.prop('disabled', true).val('');

    const url = ajaxUrl.replace(/0\/0/, `${$manu.val()}/${$(this).val()}`);

    $.get(url + '/' + linkingTargetType, (data) => {
        $type.find('option').not(':first').remove();
        $type.prop('disabled', false);

        $.each(data, (_, model) => {
            const option = new Option(model.name, model.value);

            $type.append($(option));
        });
    });
});

$('body').on('change', '#vehicle_type', function () {
    $(this).parents('form[name="vehicle"]').submit();
});

});
